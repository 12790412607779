// --------------------------------------------------
// REACT
// --------------------------------------------------
import React from 'react';
import PropTypes from 'prop-types';

// --------------------------------------------------
// HELPERS
// --------------------------------------------------
import { smartypants } from '../../helpers/helpers';

// --------------------------------------------------
// COMPONENTS
// --------------------------------------------------
import { Link } from 'gatsby';

// --------------------------------------------------
// STYLES
// --------------------------------------------------
import styles from './header.module.scss';


const Header = ( { title, isHome, is404, projectTitle, projectTitleInView, previousProject, nextProject } ) => (
	<header className={`${styles.header} ${isHome ? styles.isHome : ''} ${is404? styles.is404 : ''}`}>
		{isHome ? (
			<h1 className={styles.heading}>{title}</h1>
		) : (
			<>
				<h1 className={styles.siteTitle}>
					<Link to={'/'}>{title}</Link>
				</h1>

				{projectTitle && (
					<h2 className={`${styles.projectTitle} ${previousProject && nextProject ? styles.shrink : ''} ${!projectTitleInView ? styles.isVisible : ''}`} aria-hidden={true}>{smartypants( projectTitle )}</h2>
				)}

				{( previousProject || nextProject ) && (
					<div className={`${styles.projectNav} ${previousProject && nextProject ? styles.grow : ''}`}>
						{previousProject ? (
							<Link to={previousProject} className={styles.prevNextProject}>Previous Project</Link>
						) : ''}

						{nextProject ? (
							<Link to={nextProject} className={styles.prevNextProject}>Next Project</Link>
						) : ''}
					</div>
				)}

			</>
		)}
	</header>
);

Header.defaultProps = {
	title: '(title is missing)',
	isHome: false,
	is404: false,
	projectTitle: null,
	projectTitleInView: false,
	previousProject: null,
	nextProject: null,
};

Header.propTypes = {
	title: PropTypes.string.isRequired,
	isHome: PropTypes.bool,
	is404: PropTypes.bool,
	projectTitle: PropTypes.string,
	projectTitleInView: PropTypes.bool,
	previousProject: PropTypes.string,
	nextProject: PropTypes.string,
};

export default Header;
