/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

// --------------------------------------------------
// REACT
// --------------------------------------------------
import React from 'react';
import PropTypes from 'prop-types';

// --------------------------------------------------
// HELPERS
// --------------------------------------------------
import { useStaticQuery, graphql } from 'gatsby';
import { prependSiteUrl } from '../helpers/helpers';

// --------------------------------------------------
// COMPONENTS
// --------------------------------------------------
import { Helmet } from 'react-helmet';
import { JsonLd } from 'react-schemaorg';


const SEO = ( { description, lang, meta, title, isHome, metaThumb } ) => {
	const { site, metaImg, siteIcon } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						author
						siteUrl
					}
				}
				metaImg: file(name: { eq: "og-image" }) {
					childImageSharp {
						original {
							width
							height
							src
						}
					}
				}
				siteIcon: file(name: { eq: "favicon" }) {
					childImageSharp {
						original {
							width
							height
							src
						}
					}
				}
			}
		`,
	);

	const titleTemplate = isHome ? '%s' : `%s | ${site.siteMetadata.title}`;

	const metaDescription = description || site.siteMetadata.description;

	const ogImage = metaThumb || metaImg.childImageSharp.original;

	return (
		<>
			<Helmet
				htmlAttributes={{
					lang,
				}}
				title={title}
				titleTemplate={titleTemplate}
				meta={[
					{
						name: 'description',
						content: metaDescription,
					},
					{
						property: 'og:title',
						content: title,
					},
					{
						property: 'og:description',
						content: metaDescription,
					},
					{
						property: 'og:type',
						content: 'website',
					},
					{
						property: 'og:image',
						content: prependSiteUrl( ogImage.src ),
					},
					{
						property: 'og:image:width',
						content: ogImage.width,
					},
					{
						property: 'og:image:height',
						content: ogImage.height,
					},
					{
						name: 'twitter:card',
						content: 'summary',
					},
					{
						name: 'twitter:creator',
						content: site.siteMetadata.author,
					},
					{
						name: 'twitter:title',
						content: title,
					},
					{
						name: 'twitter:description',
						content: metaDescription,
					},
				].concat( meta )}
			/>
			<JsonLd
				item={{
					'@context': 'https://schema.org',
					'@type': 'WebSite',
					'name': site.siteMetadata.title,
					'url': site.siteMetadata.siteUrl,
					'abstract': site.siteMetadata.description,
				}}/>
			<JsonLd
				item={{
					'@context': 'https://schema.org',
					'@type': 'Person',
					'name': site.siteMetadata.author,
					'url': site.siteMetadata.siteUrl,
				}}/>
		</>
	);
};

SEO.defaultProps = {
	description: '',
	lang: 'en',
	meta: [],
	title: '(title is missing)',
	isHome: false,
};

SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf( PropTypes.object ),
	title: PropTypes.string.isRequired,
	isHome: PropTypes.bool,
};

export default SEO;
