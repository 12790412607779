// --------------------------------------------------
// REACT
// --------------------------------------------------
import React from 'react';
import PropTypes from 'prop-types';

// --------------------------------------------------
// STYLES
// --------------------------------------------------
import styles from './main.module.scss';


const Main = ( { children, is404 } ) => (
	<main id="main-content" className={`${styles.main} ${is404 ? styles.is404 : ''}`}>{children}</main>
);

Main.defaultProps = {
	is404: false,
};

Main.propTypes = {
	is404: PropTypes.bool,
	children: PropTypes.node,
};

export default Main;
