const smartypants = require( 'smartypants' );

const helpers = {
	imageExtensions: ['jpg', 'png', 'gif', 'bmp'],
	videoExtensions: ['mp4'],

	/**
	 * Get a 'sizes' image attribute value
	 *
	 * @param {string} containerWidth Outer container width. Almost always '100vw'
	 * @param {number} sitePadding Width of one side of site padding (px/rem/em)
	 * @param {number} gutterWidth Grid gutter width (px/rem/em)
	 * @param {number} colsTotal # of grid columns
	 * @param {number} colsOccupied # of columns occupied
	 *
	 * @example
	 * getSizesString( '100vw', 30, 30, 6, 4 )
	 * // returns 'calc( ( 3 * 30px ) + ( 4 * ( ( ( 100vw - 60px ) - 150px ) / 6 ) ) )'
	 *
	 * @return {string}
	 */
	getSizesString(
		containerWidth = '100vw',
		sitePadding = 0,
		gutterWidth = 0,
		colsTotal = 1,
		colsOccupied = 1,
	) {
		const sitePaddingTotal = sitePadding ? `${sitePadding * 2}px` : sitePadding;
		const workingArea = sitePadding ? `( ${containerWidth} - ${sitePaddingTotal} )` : containerWidth;
		const gutterWidthTotal = gutterWidth * ( colsTotal - 1 );

		// Calculate total gutter width occupied
		let totalGutterWidthOccupied;

		if ( colsOccupied - 1 === 0 ) {
			totalGutterWidthOccupied = 0;
		} else {
			totalGutterWidthOccupied = `( ${colsOccupied - 1} * ${gutterWidth}px )`;
		}

		// Calculate single column width
		let singleColWidth;
		if ( colsTotal === 1 ) {
			if ( gutterWidthTotal === 0 ) {
				singleColWidth = `${workingArea}`;
			} else {
				singleColWidth = `( ${workingArea} - ${gutterWidthTotal}px )`;
			}
		} else {
			if ( gutterWidthTotal === 0 ) {
				singleColWidth = `( ${workingArea} / ${colsTotal} )`;
			} else {
				singleColWidth = `( ( ${workingArea} - ${gutterWidthTotal}px ) / ${colsTotal} )`;
			}
		}

		// Calculate total column width occupied
		let totalColumnWidthOccupied;
		if ( colsOccupied === 1 ) {
			totalColumnWidthOccupied = `${singleColWidth}`;
		} else {
			totalColumnWidthOccupied = `( ${colsOccupied} * ${singleColWidth} )`;
		}

		let finalSize;
		if ( totalGutterWidthOccupied === 0 ) {
			finalSize = `calc( ${totalColumnWidthOccupied} )`;
		} else {
			finalSize = `calc( ${totalGutterWidthOccupied} + ${totalColumnWidthOccupied} )`;
		}

		return finalSize;
	},

	/**
	 * Convert a string to PascalCase
	 * https://gist.github.com/jacks0n/e0bfb71a48c64fbbd71e5c6e956b17d7
	 *
	 * @param {string} string
	 *
	 * @example
	 * toPascalCase( 'gluten-free' )
	 * // returns 'GlutenFree'
	 *
	 * @return {string} Pascal-cased string
	 */
	toPascalCase( string = '' ) {
		if ( !string || string.trim().length === 0 ) {
			return '';
		}

		return string
			.match( /[a-z]+/gi )
			.map( ( word ) => (
				word.charAt( 0 ).toUpperCase() + word.substr( 1 ).toLowerCase()
			) )
			.join( '' );
	},

	/**
	 * Put a full stop at the end of an image's description inside the alt attribute, so that screen readers can make a short pause which leads to better user experience
	 * https://webaccessibility.guide
	 *
	 * @param {string} string
	 *
	 * @example
	 * formatAltText( 'A man and his cat' )
	 * // returns 'A man and his cat.'
	 *
	 * @return {string} String with punctuation at the end
	 */
	formatAltText( string = '' ) {
		if ( !string || string.trim().length === 0 ) {
			return '';
		}

		const endingPunctuation = [ '.', '?', '!' ];
		const newString = string.trim();
		const lastCharacter = newString.substr( -1 );

		if ( endingPunctuation.includes( lastCharacter ) ) {
			return newString;
		} else {
			return `${newString}.`;
		}
	},

	/**
	 * Check if Gatsby is in development mode, as opposed to production
	 *
	 * @return {boolean} true if in development mode; false if not
	 */
	isDevelopment() {
		return process.env.NODE_ENV === 'development';
	},

	/**
	 * Prepend site URL to string
	 *
	 * @param {string} string Any string, but probably a path
	 * @param {boolean} addSlashAfterSiteUrl If true, adds a slash after the site URL
	 *
	 * @example `prependSiteUrl( '/recipe/pizza', false );`
	 *
	 * @return {string}
	 */
	prependSiteUrl( string, addSlashAfterSiteUrl = false ) {
		return `https://scottrank.in${ addSlashAfterSiteUrl ? '/' : '' }${string}`;
	},

	/**
	 * Replace dumb quotes and stuff
	 * https://github.com/othree/smartypants.js/
	 *
	 * @param {string} string
	 *
	 * @example `smartypants( 'I\'m building Scott\'s site.' );`
	 *
	 * @return {string}
	 */
	smartypants( string ) {
		return smartypants.smartypantsu( string );
	},
};

module.exports = helpers;
