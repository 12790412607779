// --------------------------------------------------
// REACT
// --------------------------------------------------
import React from 'react';
import PropTypes from 'prop-types';

// --------------------------------------------------
// COMPONENTS
// --------------------------------------------------
import SkipButton from '../components/a11y-skip/button';
import Header from '../components/header/header';
import Main from '../components/main/main';
import Footer from '../components/footer/footer';

// --------------------------------------------------
// STYLES
// --------------------------------------------------
import '../styles/base/manifest.scss';
import '../styles/fonts/font-face.css';


const Layout = ( { location, title, projectTitle, previousProject, nextProject, children, is404, contactInfo, colophon, projectTitleInView } ) => {
	const rootPath = `${__PATH_PREFIX__}/`;

	return (
		<>
			<SkipButton is404={is404}/>
			<Header title={title} isHome={location.pathname === rootPath} is404={is404} projectTitle={projectTitle} projectTitleInView={projectTitleInView} previousProject={previousProject} nextProject={nextProject}/>
			<Main is404={is404} children={children}/>
			<Footer title={title} is404={is404} contactInfo={contactInfo} colophon={colophon}/>
		</>
	);
};

Layout.defaultProps = {
	projectTitle: null,
	projectTitleInView: false,
	previousProject: null,
	nextProject: null,
	is404: false,
	contactInfo: null,
	colophon: null,
};

Layout.propTypes = {
	location: PropTypes.object,
	title: PropTypes.string.isRequired,
	previousProject: PropTypes.string,
	nextProject: PropTypes.string,
	projectTitle: PropTypes.string,
	projectTitleInView: PropTypes.bool,
	children: PropTypes.node,
	is404: PropTypes.bool,
	contactInfo: PropTypes.object,
	colophon: PropTypes.array,
};

export default Layout;
