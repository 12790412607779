// --------------------------------------------------
// REACT
// --------------------------------------------------
import React from 'react';
import PropTypes from 'prop-types';

// --------------------------------------------------
// STYLES
// --------------------------------------------------
import styles from './button.module.scss';


const Button = ( { is404 } ) => {
	const className = is404 ? styles.button404 : styles.button;

	return (
		<a href="#main-content" className={className}>Skip to main content</a>
	);
};

Button.defaultProps = {
	is404: false,
};

Button.propTypes = {
	is404: PropTypes.bool,
};

export default Button;
