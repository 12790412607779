// --------------------------------------------------
// REACT
// --------------------------------------------------
import React from 'react';
import PropTypes from 'prop-types';

// --------------------------------------------------
// HELPERS
// --------------------------------------------------
import { smartypants } from '../../helpers/helpers';

// --------------------------------------------------
// COMPONENTS
// --------------------------------------------------
import Obfuscate from 'react-obfuscate';
import Modal from 'react-modal';

// --------------------------------------------------
// STYLES
// --------------------------------------------------
import styles from './footer.module.scss';
import modalStyles from './modal.module.scss';


Modal.setAppElement( '#___gatsby' );

const Footer = ( { title, is404, contactInfo, colophon } ) => {
	const [ modalIsOpen, setModalIsOpen ] = React.useState( false );
	const dateNow = new Date();

	return (
		is404 ? (
			<footer className={styles.footer404}>
				<dl>
					<dt>E<span className={styles.labelHide}>mail</span>:&nbsp;</dt>
					<dd>
						<Obfuscate email={contactInfo.email} />
					</dd>
					<dt>M<span className={styles.labelHide}>obile</span>:&nbsp;</dt>
					<dd>
						<Obfuscate tel={contactInfo.phone} />
					</dd>
				</dl>
			</footer>
		) : (
			<footer className={styles.footer}>
				<button onClick={() => setModalIsOpen( true )} type="button">
					<span>Colophon</span>
				</button>
				<Modal
					isOpen={modalIsOpen}
					onRequestClose={() => setModalIsOpen( false )}
					contentLabel="Colophon"
					className={modalStyles.modal}
					className={ {
						base: modalStyles.container,
						afterOpen: modalStyles.afterOpen,
						beforeClose: modalStyles.beforeClose,
					} }
					overlayClassName={ {
						base: modalStyles.overlay,
						afterOpen: modalStyles.afterOpen,
						beforeClose: modalStyles.beforeClose,
					} }
					bodyOpenClassName={modalStyles.bodyOpen}
					portalClassName={modalStyles.portal}
				>
					<button className={modalStyles.button} onClick={() => setModalIsOpen( false )}>Close <span aria-hidden="true">X</span></button>

					<h2 id="colphon-heading" className={modalStyles.heading}>
						<svg viewBox="0 0 260 37">
							<path d="M27.068 33.591c-4.674 2.191-8.714 3.116-12.074 3.116C4.624 36.707 0 27.993 0 19.181 0 10.077 4.868.876 13.388.876c3.651 0 7.984 1.704 12.9 5.794-13.29 5.306-19.375 9.444-19.375 13.387 0 4.333 7.205 8.423 20.155 13.534zM47.839.78c9.932 0 14.751 8.227 14.751 16.698 0 9.007-5.501 18.354-16.065 18.354S30.8 27.603 30.8 19.084C30.8 10.077 36.593.779 47.84.779zm18.673 34.565C68.264 10.759 71.624.097 73.863.097c1.314 0 2.24 3.603 2.24 10.126 0 4.236-.39 9.786-1.315 16.358 9.737 1.071 14.654 2.97 14.654 4.674 0 2.142-7.595 4.089-22.93 4.089zM110.745.779c9.931 0 14.75 8.227 14.75 16.698 0 9.007-5.5 18.354-16.064 18.354-10.565 0-15.725-8.228-15.725-16.747 0-9.007 5.793-18.305 17.039-18.305zm25.557 22.394C134.744 33.202 133.429 37 132.504 37c-1.314 0-1.996-6.864-1.996-14.946 0-7.448.585-15.968 1.85-20.933a36.958 36.958 0 014.48-.292c9.298 0 13.728 4.625 13.728 9.736 0 5.404-4.966 11.246-14.264 12.61zM180.309.146c1.753 0 2.824 4.43 2.824 16.358 0 14.215-1.266 19.375-3.262 19.375-2.531 0-6.231-8.422-9.882-17.039 3.894-9.2 7.789-18.694 10.32-18.694zm-20.446.536c2.482 0 6.28 9.152 10.126 18.158-3.652 8.617-7.303 17.04-9.786 17.04-1.947 0-3.164-5.015-3.164-18.792 0-11.976 1.071-16.406 2.824-16.406zm46.115.097c9.931 0 14.751 8.227 14.751 16.698 0 9.007-5.501 18.354-16.065 18.354-10.565 0-15.725-8.228-15.725-16.747 0-9.007 5.793-18.305 17.039-18.305zm43.214 24.341C252.454 6.524 255.23 0 257.176 0 258.978 0 260 5.696 260 12.658c0 10.661-2.483 24.244-8.52 24.244-3.7 0-8.762-5.112-15.383-19.23-2.63 13.729-4.82 18.743-6.475 18.743-1.85 0-2.97-6.475-2.97-13.923 0-10.078 2.045-21.956 6.913-21.956 3.554 0 8.666 6.377 15.627 24.584z" fill="#fff" fill-rule="evenodd"/>
						</svg>
						<span className={modalStyles.hideVisually}>Colophon</span>
					</h2>

					<div className={modalStyles.copy}>
						<div className={modalStyles.copyInner}>
							{colophon.map( ( group, index ) => (
								<React.Fragment key={index}>
									{group.heading && <h2 dangerouslySetInnerHTML={{ __html: smartypants( group.heading ) }}/>}
									<p dangerouslySetInnerHTML={{ __html: smartypants( group.content ) }}/>
								</React.Fragment>
							) )}
							<small className={modalStyles.copyright}>&copy; {dateNow.getFullYear()} {title}. All rights reserved.</small>
						</div>
					</div>


				</Modal>
			</footer>
		)
	);
};

Footer.defaultProps = {
	isHome: false,
	is404: false,
	contactInfo: null,
	colophon: null,
};

Footer.propTypes = {
	title: PropTypes.string.isRequired,
	is404: PropTypes.bool,
	contactInfo: PropTypes.object,
	colophon: PropTypes.array,
};

export default Footer;
